import React, { Fragment } from "react"
import Helmet from "../components/Helmet"
import Header from "../components/Header"
import One from "../privacyPage/One"
import Footer from "../components/Footer"

const PrivacyPage = () => (
  <Fragment>
    <Helmet
      title="Privacy Policy"
      descr="Read our privacy policy to get a clear understanding of how we collect, use, protect or otherwise handle your Personally Identifiable Information in accordance with use of our web site."
      slug="/privacy"
    />
    <Header />
    <One />
    <Footer />
  </Fragment>
)

export default PrivacyPage
