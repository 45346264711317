import React from "react"
import { Link } from "gatsby"
import H1 from "../../components/H1"
import EmailLink from "../../components/EmailLink"
import * as styles from "./styles.module.css"

const One = () => {
  return (
    <section className={styles.one}>
      <H1 text="ETF Stock Report Privacy Policy"></H1>

      <div className={styles.grid}>
        <div>
          <p>
            Celestial Creative Solutions, LLC. owns and operates the
            https://etfstockreport.com web site (“Site”/ “Service”/
            “Subscription Service”). Celestial Creative Solutions, LLC the
            operator of this Site (indicated as “us” or “we”), is devoted to
            protecting your private data.
          </p>

          <p>
            This writing is to notify our customer/users/visitors about how our
            organization gathers, discloses, and uses our
            customer/users/visitor’s personal data when they access our web
            site. Our aim is to use the data you supply us with, to help give
            our Customer/User the best customer product and service experience.
            Your data helps us upgrade our product and service. When accessing
            and using our Site/Service the customer/user/visitor assents to the
            gathering of their data and the use of the data, to be used within
            the confines of this policy. The Terms used in our Privacy Policy
            are defined in the same manner with etfstockreport.com{" "}
            <Link to="terms">Terms and Conditions</Link>.
          </p>

          <p>
            By accessing or entering this web site customer/user/visitor agrees
            to follow all terms of this Privacy Policy, as well as Terms and
            Conditions. If the customer/user/visitor disagrees to any of the
            provisions, they should not enter nor use this web site or Service.
          </p>

          <h3>Personal Data / Personal Identifiable Information</h3>

          <p>
            Our web site does not collect any Personal Data about our
            customer/visitor/user unless he or she voluntarily provides it.
            While using our Site and Service, our customers/users/visitors may
            be requested to supply us with certain pieces of data, that can we
            can use to communicate with or to establish identify and means of
            payment. Personal Data can include, but is not limited to:
            <ul>
              <li>First and Last Name</li>
              <li>
                Address, State, Province, Postal/Zip Code, City/ Town, County
              </li>
              <li>Email address</li>
              <li>Phone number</li>
            </ul>
          </p>

          <p>
            Etfstockreport.com may use your Personal Data to reach out to you
            regarding marketing/promotional information, new information,
            newsletters, product offerings, special offers, and updates.
            Customers/users/visitors can elect to opt out of receiving any, or
            all, of these communications by contacting us or by selecting the
            unsubscribe tab at the bottom of the email.
          </p>
          <p>
            We may also assemble and collect data/information to see how the
            Site/Service is entered and used (“Usage Data”). The Usage Data may
            consist of your computer/mobile device’s Internet Protocol address
            (e.g., IP address), browser type/version, other diagnostic data, the
            pages/sections of the Site/Service that you access, the time spent
            on those pages/sections, the time and date when you access the
            Site/Service, and unique device identifiers.
          </p>
          <p>
            Celestial Creative Solutions, LLC. utilizes customer/user/visitor
            data for the following purposes, but is not limited to:
            <ul>
              <li>
                To deliver, sustain, and upgrade the web site and Subscription
                Service.
              </li>
              <li>
                To inform our customer/user/visitor about changes to web site
                and Subscription Service.
              </li>
              <li>
                To inform our customer/user/visitor with news about new
                products/service offerings.
              </li>
              <li>To fill orders.</li>
              <li>To give customer service/support.</li>
              <li>
                To analyze the usage of the web site and Subscription Service.
              </li>
              <li>To monitor, prevent, and fix technical problems.</li>
              <li>To monitor, prevent, and report fraud.</li>
            </ul>
          </p>

          <p>
            Our Site may also gather, or our third-party server and payment
            processor, may collect other types of Non-Personally Identifiable
            Information. Non-Personally Identifiable Information, relates to
            data/information that alone does not determine a specific person.
            This data/information is collected and reviewed on both an
            individual and a combined basis. This data/information may consist
            of the web site’s Uniform Resource Locator (“URL”) that the user was
            previously on before out site, the next URL the user visits, and the
            user’s Internet Protocol (“IP”) address. The IP addresses collected
            is used to run our site, identify and correct problems with the
            servers we use, and to collect demographic information.
          </p>

          <h3>Data / Personal Information Rights and Release Policy</h3>

          <p>
            Our company does not sell, trade, or lease your Personal
            Data/Information to others. If a customer/user/visitor does give us
            Personal Identifiable Information, he or she reserves the option to
            access, edit, export, or restrict certain sections of their Personal
            Identifiable Information they have given to us by contacting us via
            the contact us tab or email via{" "}
            <EmailLink email="celestialcreativesolutions@gmail.com" />.
          </p>
          <p>
            Our company, service providers, and payment process service does use
            Personally Identifiable Information to run our Site and to deliver
            our Subscription Service. We also use it for compliance, to fulfil
            contracts, and to process payments. Case in Point, we have to
            release your credit or debit card information, to your financial
            institute that issued the card and our payment processer (Stripe),
            to verify payment and deliver our subscription service.
          </p>
          <p>
            Celestial Creative Solutions, LLC. will retain your Personal data to
            the degree necessary to observe and conform to legal and regulatory
            obligations. In some cases, Celestial Creative Solutions, LLC. may
            be required by recognized courts and law enforcement agencies to
            disclose Personally Identifiable Information. Celestial Creative
            Solutions, LLC. will disclose Personally Identifiable Information
            upon receiving a valid court order. We reserve the right to contact
            law enforcement agencies if we believe there is unlawful activity.
          </p>
          <p>
            Other Reasons for the disclosure of data:
            <ul>
              <li>To meet a legal obligation.</li>
              <li>
                To safeguard against and determine possible misconduct with
                respect to our web site or Subscription Service.
              </li>
              <li>
                To guard and protect the rights and property Celestial Creative
                Solutions, LLC.
              </li>
              <li>To safeguard against legal liability.</li>
              <li>
                To safeguard the personal safety of our customers/visitors
                /users and general public.{" "}
              </li>
            </ul>
          </p>

          <h3>Data Analytics</h3>

          <p>
            When accessing our web site cookies will be stored on your
            computer/mobile device in order to enable and tailor your experience
            on our Site. A cookie is small data file, that is stored on your
            device, which may have an individual identifier number. Our cookies,
            help us recognize you when you visit our site. The cookies, help
            facilitate the use of our site, in addition to helping us secure the
            Site. Customer/visitor/user have to the option to accept or decline
            our cookies; however, our Site may not function correctly if the
            cookies are declined. Tracking functions such as beacons, scripts,
            and tags to gather and monitor data to help us optimize the web
            site. Our site may use third-party analytics organizations to help
            us accomplish this task.
          </p>

          <h3>Data Security</h3>

          <p>
            The security and integrity of customer/user’s information is a
            priority for this Service. Celestial Creative Solutions, LLC.,
            endeavors to use commercially acceptable means to shield your
            personal information. We ask our customer/users to keep in mind that
            no means of electronic communication and storage is 100% safe and
            secure, we cannot pledge that there will be a total defense of your
            communication and data.
          </p>

          <h3>Legal Age Verification</h3>

          <p>
            We retain the right to ask for proof of legal age (18) to access and
            use this site. Our web site and service does not address anyone
            under the 18. Our policy, is that we do not knowingly collect,
            gather, or use personally identifiable information from people that
            are under the age of 18. If a parent or guardian believes that their
            child, or children, has given us personally identifiable
            information, they should contact us.
          </p>

          <h3>Payment Services</h3>

          <p>
            Stripe.com (https://stripe.com) provides payment processing for our
            web site. We will exchange information with Stripe only to the
            degree necessary to process payments. Please reference Stipe’s
            privacy policy, if you have questions regarding their privacy policy
            and practices.
          </p>

          <h3>Third Party Sites</h3>

          <p>
            Our web site may have links to third party sites that are not owned
            nor operated by us. Selecting the link to the third party will
            transfer you to that third party site. We recommend our
            customer/user/visitors reference each site’s terms and conditions
            and privacy policy, we have no control or responsibility for third
            party organization and web sites polices and procedures.
          </p>
          <h3>Changes to the Privacy Policy</h3>

          <p>
            We retain the right to change/modify this Privacy Policy at any
            time. We will communicate via email, as well as other means, to give
            you notice about any amendments, additions, changes, deletions, or
            revisions. The email and other communications we use to notify you,
            will be prior to revisions taking effect. Changes or modifications
            will take effect once we publish them on this section.
          </p>

          <h3>Contact Information</h3>

          <p>
            Please contact us, via email{" "}
            <EmailLink email="celestialcreativesolutions@gmail.com" />, if you
            have questions regarding any of the provisions in this Privacy
            Policy.
          </p>
        </div>
      </div>
    </section>
  )
}

export default One
